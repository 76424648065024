<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>

    <agenda-component
      v-if="dialogAgenda"
      :visible="dialogAgenda"
      :cita="editedItem"
      :cliente="editedItem.cliente"
      :client="client"
      @close="dialogAgenda = false"
    />

    <base-material-card
      color="indigo"
      icon="mdi-file-cabinet"
      title="Mis expedientes"
      class="px-5 py-3"
    >
      <v-dialog
        v-model="dialogTimeLine"
        scrollable
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5 font-weight-bold mt-3">Historial expediente No. {{ idExp }} || Cliente: {{ clienteName }}</span>
          </v-card-title>

          <v-card-text>
            <v-timeline dense>
              <v-timeline-item
                v-for="(evento) in timeline"
                :key="evento.id"
                color="indigo"
                small
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <strong v-text="evento.fecha" />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                  >
                    <strong v-text="evento.evento" />
                    <div
                      class="text-text-caption"
                      v-text="evento.descripcion"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <strong v-text="evento.usuarioName" />
                  </v-col>
                </v-row>
                <hr>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="closeTimeLine"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogGstExp"
        scrollable
        persistent
        max-width="80%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5 font-weight-bold mt-3">Gestionar expediente No. {{ idExp }} - Cliente: {{ clienteName }}</span>
          </v-card-title>

          <v-card-text>
            <v-form
              ref="formGstExp"
              v-model="valid"
              :lazy-validation="lazy"
            >
              <v-container>
                <v-row v-if="editedItem.citas[0] != undefined">
                  <v-col
                    cols="12"
                    style="text-align: right"
                  >
                    <b>Cita: </b>{{ editedItem.citas[0].inicio + " - " + editedItem.citas[0].fin }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <div class="h5 pt-3">
                      <span class="font-weight-black">Oficial encargado : </span>
                      <span>{{ oficialName }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <div class="h5 pt-3">
                      <span class="font-weight-black">Estado del expediente : </span>
                      <span>{{ estadoName }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <div class="h5 pt-3">
                      <span class="font-weight-black">Procedimiento : </span>
                      <span>{{ procedimientoName }}</span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-btn
                      v-show="editedItem.estado.orden === 3 && cita_confirmada !== 'CONFIRMADA'"
                      block
                      class="ma-2 white--text"
                      @click="showAgenda"
                    >
                      Crear cita
                      <v-icon
                        right
                        dark
                      >
                        mdi-calendar-month
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-text-field
                      v-if="editedItem.estado.orden > 7"
                      v-model="editedItem.protocolo"
                      :rules="[v => !!v || 'El protocolo es requerido']"
                      label="Protocolo"
                    />
                  </v-col>
                </v-row>

                <v-subheader
                  class="text-h5 font-weight-bold mt-2"
                >
                  Clientes en proceso ({{ ocupacion }}/{{ cupos }})
                </v-subheader>

                <v-divider />

                <v-row>
                  <v-col
                    v-for="usuario in requerimientos"
                    :key="usuario.id"
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-card
                      class="mx-auto"
                    >
                      <v-toolbar
                        dense
                        dark
                        color="indigo"
                      >
                        <v-toolbar-title v-text="usuario.nombre" />
                        <v-spacer />
                        <v-btn
                          v-show="!usuario.isCliente && delUsuarioExp"
                          icon
                          @click="removeCliente(usuario.id)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-toolbar>

                      <v-list subheader>
                        <v-subheader>Documentos requeridos</v-subheader>

                        <v-list-item
                          v-for="item in usuario.documentos"
                          :key="item.id"
                        >
                          <v-list-item-avatar>
                            <v-icon
                              v-if="item.file"
                              color="info"
                              @click="descargarRequerimiento(item.idfile, item.fileName, item.storage)"
                            >
                              mdi-download
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.nombre" />
                          </v-list-item-content>

                          <v-list-item-icon>
                            <v-icon
                              v-if="item.cumple"
                              color="success"
                            >
                              mdi-check-circle
                            </v-icon>
                            <v-icon
                              v-else
                              color="error"
                            >
                              mdi-close-circle
                            </v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>

                <v-subheader
                  class="text-h5 font-weight-bold mt-2"
                >
                  Aportar documentos al expediente
                </v-subheader>

                <v-divider />

                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="5"
                  >
                    <v-select
                      v-model="editedItem.documentos.tipo"
                      :items="tipos"
                      item-text="nombre"
                      item-value="id"
                      return-object
                      prepend-icon="mdi-format-list-bulleted-type"
                      label="Tipo de documento"
                    />
                    <v-file-input
                      v-model="editedItem.documentos.file"
                      label="Seleccione documento"
                    />
                    <v-btn
                      block
                      class="ma-2 white--text"
                      @click="cargarDocumento"
                    >
                      Cargar documento
                      <v-icon
                        right
                        dark
                      >
                        mdi-cloud-upload
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="7"
                  >
                    <v-card
                      max-width="100%"
                      min-height="100%"
                      max-height="250px"
                      class="mx-auto overflow-y-auto"
                    >
                      <v-toolbar
                        dark
                        dense
                      >
                        <v-toolbar-title>Documentos cargados</v-toolbar-title>

                        <v-spacer />

                        <v-sheet
                          dark
                          class="text-text-caption"
                        >
                          Desplaza arriba/abajo para ver más documentos
                        </v-sheet>
                      </v-toolbar>

                      <v-list
                        v-if="editedItem.documentos.length > 0"
                        two-line
                      >
                        <v-list-item
                          v-for="(item, i) in editedItem.documentos"
                          :key="i"
                        >
                          <v-list-item-avatar>
                            <v-btn
                              icon
                              color="info"
                              @click="descargarRequerimiento(item.id, item.fileName, 'expedientes')"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title v-text="item.fileName" />
                            <v-list-item-subtitle
                              class="text--primary"
                              v-text="item.tipo"
                            />
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn icon>
                              <v-icon
                                color="error"
                                @click="removeFile(i, item)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-card-text v-else>
                        <p
                          class="text-center text-h3 mt-5 grey--text"
                        >
                          Aún no tenemos documentos cargados.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  v-if="editedItem.estado.orden === 1"
                >
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <div class="h5 pt-3">
                      <span class="font-weight-black">Aprueba los borradores generados para el expediente? </span>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-select
                      v-model="editedItem.confirmacion"
                      :items="yesno"
                      item-text="nombre"
                      item-value="id"
                      label="Aprueba?"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="closeGstExp"
            >
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="saveGstExp"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="tableFilters()"
        class="elevation-1"
      >
        <template v-slot:header.generalName>
          <v-select
            v-model="filterExpediente"
            attach
            :items="modules.map(function (item) {
              return {
                text: item.text,
                value: item.value
              }
            })"
            label="Situación"
            style="width:100px"
          />
        </template>
        <template v-slot:item="{ item }">
          <tr
            v-if="item.estado != undefined && item.estado != null && item.estado.color != null"
            :style="{ 'background-color': item.estado.color, 'color': 'white' }"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.clienteName }}</td>
            <td>{{ item.procedimientoName }}</td>
            <td>{{ item.estadoName }}</td>
            <td>{{ item.oficialName }}</td>
            <td>{{ item.generalName }}</td>
            <td>{{ item.protocolo }}</td>
            <td>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.generalName == 'Activo'"
                    small
                    class="mr-2"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    @click="gstExpediente(item)"
                  >
                    mdi-folder-edit-outline
                  </v-icon>
                </template>
                <span>Gestionar expediente</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    @click="verTimeline(item)"
                  >
                    mdi-timeline-clock-outline
                  </v-icon>
                </template>
                <span>Ver historial del expediente</span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-else>
            <td>{{ item.id }}</td>
            <td>{{ item.clienteName }}</td>
            <td>{{ item.procedimientoName }}</td>
            <td>{{ item.estadoName }}</td>
            <td>{{ item.oficialName }}</td>
            <td>{{ item.generalName }}</td>
            <td>{{ item.protocolo }}</td>
            <td>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.generalName == 'Activo'"
                    small
                    class="mr-2"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="gstExpediente(item)"
                  >
                    mdi-folder-edit-outline
                  </v-icon>
                </template>
                <span>Gestionar expediente</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="verTimeline(item)"
                  >
                    mdi-timeline-clock-outline
                  </v-icon>
                </template>
                <span>Ver historial del expediente</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import ExpedientesApi from '@/services/api/Expedientes'
  import ProcedimientosApi from '@/services/api/Procedimientos'
  import ClientesApi from '@/services/api/Clientes'
  import MaestrosTipoDocApi from '@/services/api/MaestrosTipoDocumento'
  import EventBus from '../../../bus'

  export default {
    name: 'ClienteExpedientes',

    components: {
      AgendaComponent: () => import('@/views/dashboard/component/Agenda'),
    },

    data () {
      return {
        idExp: 0,
        filterExpediente: 'all',
        modules: [
          {
            text: 'All',
            value: 'all',
          },
          {
            text: 'Activo',
            value: 'Activo',
          },
          {
            text: 'Inactivo',
            value: 'Inactivo',
          },
        ],
        client: true,
        clienteName: '',
        oficialName: '',
        estadoName: '',
        procedimientoName: '',
        valid: true,
        validAddCli: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        dialog: false,
        dialogGstExp: false,
        dialogTimeLine: false,
        dialogAddCliente: false,
        dialogAgenda: false,
        expedientes: [],
        timeline: [],
        requerimientos: [],
        editedIndex: -1,
        lazy: false,
        tipos: [],
        addCliente: '',
        cita_confirmada: '',
        estadoGeneral: [
          { value: 'A', nombre: 'Activo' },
          { value: 'I', nombre: 'Inactivo' },
        ],
        yesno: [
          { value: 'yes', nombre: 'Si' },
          { value: 'no', nombre: 'No' },
        ],
        headers: [
          {
            text: 'N°. Exp',
            align: 'left',
            sortable: false,
            value: 'id',
          },
          {
            text: 'Cliente',
            align: 'left',
            sortable: false,
            value: 'clienteName',
          },
          {
            text: 'Procedimiento',
            align: 'left',
            sortable: false,
            value: 'procedimientoName',
          },
          {
            text: 'Estado',
            align: 'left',
            sortable: false,
            value: 'estadoName',
          },
          {
            text: 'Oficial',
            align: 'left',
            sortable: false,
            value: 'oficialName',
          },
          {
            text: 'Situación',
            align: 'left',
            sortable: false,
            value: 'generalName',
          },
          {
            text: 'Protocolo',
            align: 'left',
            sortable: false,
            value: 'protocolo',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          id: '',
          citas: '',
          cliente: '',
          clienteName: '',
          procedimiento: '',
          procedimientoName: '',
          estado: '',
          estadoName: '',
          oficial: '',
          oficialName: '',
          observaciones: '',
          general: 'A',
          generalName: 'Activo',
          documentos: [],
          encargadonew: null,
          estadonew: null,
          usuarios: [],
          confirmacion: '',
          protocolo: null,
        },
        defaultItem: {
          id: '',
          citas: '',
          cliente: '',
          clienteName: '',
          procedimiento: '',
          procedimientoName: '',
          estado: '',
          estadoName: '',
          oficial: '',
          oficialName: '',
          observaciones: '',
          general: 'A',
          generalName: 'Activo',
          documentos: [],
          encargadonew: null,
          estadonew: null,
          usuarios: [],
          confirmacion: '',
          protocolo: null,
        },
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo expediente' : 'Modificar expediente'
      },
      cupos () {
        return this.editedItem.participantes
      },
      ocupacion () {
        return this.requerimientos.length
      },
      delUsuarioExp () {
        if (localStorage.getItem('role') !== 'ROLE_CLIENTE') {
          return true
        } else {
          return false
        }
      },
    },

    watch: {
      dialogGstExp (val) {
        val || this.closeGstExp()
      },
      dialogTimeLine (val) {
        val || this.closeTimeLine()
      },
      filterExpediente: function (newValue, oldValue) {
        this.tableFilters()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchExpedientes () {
        ExpedientesApi.getAllExpedientesUsuario()
          .then(expedientes => {
            this.expedientes = expedientes
            this.tableFilters()
          })
          .catch(error => console.log(error))
      },
      fetchTipoDoc () {
        MaestrosTipoDocApi.getAllTipoDoc()
          .then(tipos => {
            this.tipos = tipos
          })
          .catch(error => console.log(error))
      },
      loadUsuarios (id) {
        ExpedientesApi.getRequerimientos(id)
          .then(requerimientos => {
            this.requerimientos = requerimientos
          })
          .catch(error => console.log(error))
      },
      initialize () {
        this.fetchExpedientes()
        EventBus.$on('update-date', () => {
          this.getExpedienteById(this.editedItem)
        })
      },

      gstExpediente (item) {
        ExpedientesApi.getRequerimientos(item.id)
          .then(requerimientos => {
            this.requerimientos = requerimientos
            this.editedIndex = this.expedientes.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.idExp = item.id
            this.clienteName = item.clienteName
            this.estadoName = item.estado.nombre
            this.oficialName = item.oficialName
            this.procedimientoName = item.procedimientoName
            this.editedItem.encargadonew = item.encargado
            this.editedItem.estadonew = item.estado
            this.dialogGstExp = true
            this.getExpedienteById(item)
            // this.expedienteFinalizado(item.id)
            this.fetchTipoDoc()
            this.getDocumentosVencidos(item)
          })
          .catch(error => console.log(error))
      },

      getDocumentosVencidos (item) {
        var Hoy = new Date()
        var AnyoHoy = Hoy.getFullYear()
        var MesHoy = Hoy.getMonth()
        var DiaHoy = Hoy.getDate()
        var count = 0
        item.usuarios.forEach(element => {
          element.documentos.forEach(document => {
            var fecha = document.expira.split('/')
            var fechaEval = new Date(fecha[2], fecha[1] - 1, fecha[0])

            var AnyoFecha = fechaEval.getFullYear()
            var MesFecha = fechaEval.getMonth()
            var DiaFecha = fechaEval.getDate()

            if (AnyoHoy > AnyoFecha && count === 0) {
              this.text = 'Posee documentos vencidos'
              this.snackbar = true
              this.colorSnak = 'info'
              this.timeout = -1
              count = 1
            } else {
              if (AnyoHoy === AnyoFecha && MesFecha < MesHoy && count === 0) {
                this.text = 'Posee documentos vencidos'
                this.snackbar = true
                this.colorSnak = 'info'
                this.timeout = -1
                count = 1
              } else {
                if (AnyoFecha === AnyoHoy && MesFecha === MesHoy && DiaFecha < DiaHoy && count === 0) {
                  this.text = 'Posee documentos vencidos'
                  this.snackbar = true
                  this.colorSnak = 'info'
                  this.timeout = -1
                  count = 1
                } else {
                  if (AnyoFecha === AnyoHoy && MesFecha === MesHoy && DiaFecha === DiaHoy && count === 0) {
                    this.text = 'Posee documentos que vencen el día de hoy'
                    this.snackbar = true
                    this.colorSnak = 'info'
                    this.timeout = -1
                    count = 1
                  }
                }
              }
            }
          })
        })
        setTimeout(() => {
          this.timeout = 3000
        }, 10000)
      },

      getExpedienteById (item) {
        ExpedientesApi.getExpedientesById(item.id)
          .then(expediente => {
            this.editedItem.citas = expediente.citas
            this.cita_confirmada = (expediente.citas[0] !== undefined) ? expediente.citas[0].estado : ''
          })
      },

      cargarDocumento () {
        var value = this.editedItem.documentos.file
        if (!value) {
          this.text = 'Debes seleccionar un documento'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        var value2 = this.editedItem.documentos.tipo
        if (!value2) {
          this.text = 'Debes seleccionar un tipo de documento'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const formData = new FormData()
        formData.append('_file', this.editedItem.documentos.file)
        formData.append('expediente', this.editedItem.id)
        formData.append('tipo', this.editedItem.documentos.tipo.id)

        ExpedientesApi.uploadFile(formData)
          .then(response => {
            const name = this.tipos.find(e => e.id === this.editedItem.documentos.tipo.id)
            this.editedItem.tipoName = name.nombre

            this.editedItem.documentos.push({
              id: response.id,
              fileName: this.editedItem.documentos.file.name,
              tipo: this.editedItem.tipoName,
              tipoid: value2.id,
              file: response.file,
            })
            const req = this.requerimientos[0].documentos.find(e => e.id === value2.id)
            req.cumple = true
            req.file = response.file
            req.idfile = response.id
            req.fileName = this.editedItem.documentos.file.name
            this.editedItem.documentos.file = null
            this.editedItem.documentos.tipo = ''
            this.text = 'Documento cargado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El documento no puede ser cargado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      saveGstExp () {
        if (this.$refs.formGstExp.validate()) {
          ExpedientesApi.updateEstadoExpedientes(this.editedItem.id, this.editedItem)
            .then(response => {
              const expediente = this.expedientes.find(e => e.id === this.editedItem.id)
              if (typeof response.estado === 'object' && response.estado !== null) {
                expediente.estado = response.estado
                expediente.estadoName = response.estado.nombre
              }
              this.text = 'Registro modificado correctamente'
              this.snackbar = true
              this.colorSnak = 'success'
              this.editedIndex = -1
              // this.finalizado = response.final
              if (response.message !== null) {
                setTimeout(() => {
                  this.text = response.message
                  this.snackbar = true
                  this.colorSnak = 'info'
                  this.timeout = 5000
                  expediente.estado = this.editedItem.estado
                  expediente.estadoName = this.editedItem.estado.nombre
                }, 5000)
                this.timeout = 3000
              } else {
                expediente.estado = response.estado
                expediente.estadoName = response.estado.nombre
              }
            })
            .catch(error => {
              console.log(error)
              this.text = 'El registro no puede ser modificado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
              this.editedIndex = -1
            })

          this.closeGstExp()
        }
      },

      closeGstExp () {
        this.dialogGstExp = false
        // this.finalizado = false
      },

      verTimeline (item) {
        ExpedientesApi.getTimeLine(item.id)
          .then(timeline => {
            this.timeline = timeline
            this.idExp = item.id
            this.clienteName = item.clienteName
            this.dialogTimeLine = true
          })
          .catch(error => console.log(error))
      },

      closeTimeLine () {
        this.dialogTimeLine = false
      },

      setNombreOficial (i) {
        const name = this.empleados.find(e => e.id === i)
        this.editedItem.oficialName = name.nombreCompleto
      },

      setNombreEstado (i) {
        this.editedItem.estadoName = i.nombre
      },

      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },

      downloadFile (i, name) {
        const nombre = name
        ClientesApi.downloadFile(i)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },

      downloadFilePro (i, name) {
        const nombre = name
        ProcedimientosApi.downloadFile(i)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },

      downloadFileExp (i, name) {
        const nombre = name
        ExpedientesApi.downloadFile(i)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },

      descargarRequerimiento (i, name, storage) {
        switch (storage) {
          case 'clientes':
            this.downloadFile(i, name)
            break
          case 'procedimientos':
            this.downloadFilePro(i, name)
            break
          case 'expedientes':
            this.downloadFileExp(i, name)
            break
        }
      },

      removeFile (i, item) {
        const index = i
        const doc = item
        if (confirm('Realmente desea eliminar este registro?')) {
          ExpedientesApi.deleteFile(doc.id)
            .then(response => {
              const req = this.requerimientos[0].documentos.find(e => e.id === doc.tipoid)
              req.cumple = false
              req.file = ''
              req.fileName = ''
              this.editedItem.documentos.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      showAgenda () {
        if (this.dialogAgenda) {
          this.dialogAgenda = false
        } else {
          this.dialogAgenda = true
        }
      },

      tableFilters () {
        var data = this.expedientes
        var select = this.filterExpediente
        switch (select) {
          case 'Activo' :
            data = data.filter(function (item, index) {
              return item.generalName.includes(select)
            })
            break
          case 'Inactivo':
            data = data.filter(function (item, index) {
              return item.generalName.includes(select)
            })
            break
          default:
            data = this.expedientes
            break
        }
        return data
      },

    },
  }
</script>
<style>
.v-timeline-item {
    padding-bottom: 0px !important;
}
.v-data-table__wrapper {
    overflow-y: auto !important;
}
</style>
